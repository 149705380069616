import React, { useRef } from 'react';
import Hero from '../components/sections/Hero';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Cta from '../components/sections/Cta';

import { analytics } from "../components/sections/firebase";
import Testimonial from '../components/sections/Testimonial';



const scrollToRef = (ref) => window.scrollTo({behavior: 'smooth', top:2875})
const scrollToFeat = (ref) => window.scrollTo({behavior: 'smooth', top:1000})



const Home = () => {

  const myRef = useRef(null)
  const execScroll = () => {
    scrollToRef(myRef);
    analytics.logEvent('our products/pricing button clicked');
  };

  const featRef= useRef(null)
  const execScrollFeat = () => {
    scrollToFeat(featRef);
    analytics.logEvent('explore features clicked');
  };

  return (
    <>
      <Hero className="illustration-section-01" learnOnClick={execScroll} prodOnClick={execScrollFeat}/>
      {/* <FeaturesTiles /> */}
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" ref={myRef}/>
      <Testimonial></Testimonial>
      <Cta split/>
    </>
  );
}

export default Home;