import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import TextField from '@material-ui/core/TextField';


import { db } from "./firebase";
import { Button } from '@material-ui/core';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    // topDivider && 'has-top-divider',
    // bottomDivider && 'has-bottom-divider',
    //split && 'cta-split'
  );  

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const[lastName, setLastName] = useState("");
  const[work, setWork] = useState("");
  const[msg, setMessage] = useState("");
// eslint-disable-next-line
  const [loader, setLoader] = useState(false); 
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    db.collection("contacts")
      .add({
        email: email,
        firstName: firstName,
        lastName: lastName,
        work: work,
        msg: msg,
      })
      .then(() => {
        setLoader(false);

        document.getElementById("confirm").innerHTML = "Thank you for submitting your info!";

      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setEmail("");
    setFirstName("");
    setLastName("");
    setWork("");
    setMessage("");
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
    <head>
    <script src="https://www.google.com/recaptcha/api.js" async defer></script>
    </head>
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              Contact us for a demo or pricing
              </h3>
          </div>

          <form className="form" onSubmit={handleSubmit}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start"}} >
                <TextField id="firstName" 
                        type="firstName" 
                        label="First Name" 
                        // labelHidden hasIcon="right" 
                        // placeholder="first name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{ paddingRight: "5%" }}
                        required>

                </TextField>
                <TextField id="lastName" 
                        type="lastName" 
                        label="Last Name" 
                        // labelHidden hasIcon="right" 
                        // placeholder="last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}>
                </TextField>
              </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                <TextField id="newsletter" 
                        type="email" 
                        label="Email" 
                        labelHidden hasIcon="right" 
                        // placeholder="piece@gmail.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ paddingRight: "5%" }}
                        required>

                </TextField>
                <TextField id="work" 
                        type="work" 
                        label="Occupation" 
                        // labelHidden hasIcon="right" 
                        // placeholder="work"
                        value={work}
                        onChange={(e) => setWork(e.target.value)}>

                </TextField>
              </div>
              <div>
                <TextField id="message" 
                        type="message" 
                        label="Message" 
                        // labelHidden hasIcon="right" 
                        fullWidth
                        multiline
                        rows={4}
                        // placeholder="message"
                        value={msg}
                        onChange={(e) => setMessage(e.target.value)}>
                </TextField>
              </div>
              {/* <label> */}
              <div style={{paddingTop: "2%", display: "flex", flexDirection: "row"}}>
                <div>
                  <div class="g-recaptcha" data-sitekey="6Ld73jAaAAAAANSqrepJePhfrUnP8RKM0djy8jpD"></div>
                  <Button variant="contained" type= "submit" value="Submit" size="small"> Submit</Button>
                </div>
                <div style={{paddingLeft: "2%"}}>
                  <p id="confirm"></p>
                </div>
              </div>

                  {/* <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#376DF9" />
                  </svg> */}
              {/* </label> */}
            </div>
          </form>

        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;