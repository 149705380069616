import React from 'react';
import Pricing from '../components/sections/Pricing';

const PricingPage = () => {

  return (
    <>
      <Pricing invertMobile topDivider imageFill className="illustration-section-02"/>
    </>
  );
}

export default PricingPage;

