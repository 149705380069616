import firebase from "firebase";

var firebaseApp = firebase.initializeApp({
        apiKey: "AIzaSyB5Dj_69dZYvNZaKk-OT_di5uq86KeA55I",
        authDomain: "piece-deployment.firebaseapp.com",
        projectId: "piece-deployment",
        storageBucket: "piece-deployment.appspot.com",
        messagingSenderId: "976129047436",
        appId: "1:976129047436:web:de7f49d5a63f02cf92f4a9",
        measurementId: "G-TV8TMYMZ2K"
});

var db = firebaseApp.firestore();

var analytics = firebase.analytics();

export { db };
export {analytics};
