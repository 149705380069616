import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';


import { analytics } from './firebase';


const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const AboutTeam = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Team',
    paragraph: 'We are a passionate group of Georgia Tech students, bent on trying to impact the land real estate market.'
  };

  const analyticsFn = () => {
    analytics.logEvent('Team page viewed');
  };

  useEffect(() => {
    analyticsFn();
  });

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ height: 550 }}>
                <div>
                  <Image
                    src={require('./../../assets/images/hs-soham.png')}
                    alt="Features split 03"
                  />
                  {/* <p className="text-sm mb-0">
                      Cool description here?
                    </p>          */}
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-name text-color-high">Soham Sonthi</span>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Co-founder</span>
                  </div>
                </div>

                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <a href="https://www.linkedin.com/in/soham-sonthi/" target="blank" class="w-inline-block">
                    <img style={{ color: "#D68C45", paddingTop: 5 }} src={require('./../../assets/images/linkedin_2.svg')} alt="logo" width={32} height={32} />
                  </a>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ height: 550 }}>
                <div className="">
                  <Image
                    src={require('./../../assets/images/hs-sid.png')}
                    alt="Features split 03"
                  />
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-name text-color-high">Siddharth Natham</span>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Co-founder</span>
                  </div>
                </div>

                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <a href="https://www.linkedin.com/in/siddharth-natham/" target="blank" class="w-inline-block">
                    <img style={{ color: "#D68C45", paddingTop: 5 }} alt="logo" src={require('./../../assets/images/linkedin_2.svg')} width={32} height={32} />
                  </a>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{ height: 550 }}>
                <div className="">
                  <Image
                    src={require('./../../assets/images/hs-constantine.png')}
                    alt="Features split 03"
                  />
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-name text-color-high">Constantine Venizelos</span>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Co-founder</span>
                  </div>
                </div>

                <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                  <a href="https://www.linkedin.com/in/constantine-venizelos/" target="blank" class="w-inline-block">
                    <img style={{ color: "#D68C45", paddingTop: 5 }} alt="logo" src={require('./../../assets/images/linkedin_2.svg')} width={32} height={32} />
                  </a>
                </div>
              </div>
            </div> */}

          </div>
        </div>
      </div>
    </section>
  );
}

AboutTeam.propTypes = propTypes;
AboutTeam.defaultProps = defaultProps;

export default AboutTeam;