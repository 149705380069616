import React, { useRef } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { Link } from 'react-router-dom';
import Button from '../elements/Button';

import { analytics } from './firebase';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const scrollToRef = (ref) => window.scrollTo({behavior: 'smooth', top:4400})

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Our Products and Services',
    paragraph: 'We offer a variety of products with different tiers to tailor to your needs.'
  };

  const myRef = useRef(null)

  const dataPricing = () => {
    scrollToRef(myRef);
    analytics.logEvent('Middle Piece pricing requested');
  };

  const dronePricing = () => {
    scrollToRef(myRef);
    analytics.logEvent('Drone pricing requested');
  };

  const analysisPricing = () => {
    scrollToRef(myRef);
    analytics.logEvent('Analysis pricing requested');
  };

  const clientPricing = () => {
    scrollToRef(myRef);
    analytics.logEvent('Client presentation pricing requested');
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="text-m mt-32 mb-0 center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Middle Piece</span>
                  </div>
                  </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <p >
                      - Designed for quick searches
                    </p>
                    <p >
                      - Real time parcel, proximity and risk data
                    </p>    
                    <p >
                      - Powerful 3D visualizations
                    </p>
                    <p >
                      - Property ratings generated by custom algorithm
                    </p>                 
                    <p >
                      - Limited searches per month
                    </p>    
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button tag="a" color="#E2EEF3" wideMobile onClick={dataPricing}>
                    Request Pricing
                  </Button>
                  </div>
                </div>
                
              </div>
            </div>

            <div className="tiles-item reveal-from-left" data-reveal-delay="200">
              <div className="tiles-item-inner">
              <div className="text-m mt-32 mb-0 center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Corner Piece</span>
                  </div>
                  </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                    <p >
                      - Everything the free tier includes
                    </p>
                    <p >
                      - View the full breakdown of the ratings
                    </p>    
                    <p >
                      - View additional real time datasets
                    </p>    
                    <p >
                      - Generate and export custom reports 
                    </p>    
                    <p >
                      - Unlimited searches
                    </p>    
                    {/* <p style={{color:'#E2EEF3'}}>
                        |
                    </p> */}
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button tag="a" color="#E2EEF3" wideMobile onClick={dronePricing}>
                    Request Pricing
                  </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
              <div className="text-m mt-32 mb-0 center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Drone Analysis</span>
                  </div>
                  </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <p >
                      - Custom 3D mapping with drone service
                    </p>
                    <p >
                      - Up to date property and building analysis 
                    </p>    
                    <p >
                      - Feature extraction (tree coverage, buildings, etc)
                    </p>    
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button tag="a" color="#E2EEF3" wideMobile onClick={analysisPricing}>
                    Request Pricing
                  </Button>
                  </div>
                </div>
              </div>
            </div> */}

          </div>
          {/* <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="text-m mt-32 mb-0 center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span className="testimonial-item-link">Client Presentation</span>
                  </div>
                  </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <p >
                      - Designed to upload custom listings and batch fetch data for those properties
                    </p>
                    <p >
                      - Easy to share with clients
                    </p>    
                    <p >
                      - 3D visualizations of the property
                    </p>    
                    <p >
                      - Accurate and specific data 
                    </p>    
                </div>

                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider center">
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button tag="a" color="#E2EEF3" wideMobile onClick={clientPricing}>
                    Request Pricing
                  </Button>
                  </div>
                </div>
                
              </div>
            </div>
            </div>         */}
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;