import React from 'react';
import { VictoryLegend, VictoryChart, VictoryLine, VictoryTheme, VictoryLabel, VictoryAxis} from 'victory';

const snow = [
    {x: new Date(2020, 0, 1), y: 0},
    {x: new Date(2020, 0, 2), y: 0},
    {x: new Date(2020, 0, 3), y: 0},
    {x: new Date(2020, 0, 4), y: 0},
    {x: new Date(2020, 0, 5), y: 0},
    {x: new Date(2020, 0, 6), y: 0},
    {x: new Date(2020, 0, 7), y: 0},
    {x: new Date(2020, 0, 8), y: 0},
    {x: new Date(2020, 0, 9), y: 4},
    {x: new Date(2020, 0, 10), y: 2.5},
    {x: new Date(2020, 0, 11), y: 0.9},
    {x: new Date(2020, 0, 12), y: 0.5},
    {x: new Date(2020, 0, 13), y: 0},
    {x: new Date(2020, 0, 14), y: 7.2},
    {x: new Date(2020, 0, 15), y: 2},
    {x: new Date(2020, 0, 16), y: 1.8},
    {x: new Date(2020, 0, 17), y: 3.5},
    {x: new Date(2020, 0, 18), y: 2.4},
    {x: new Date(2020, 0, 19), y: 0},
    {x: new Date(2020, 0, 20), y: 0},
    {x: new Date(2020, 0, 21), y: 0},
    {x: new Date(2020, 0, 22), y: 0},
    {x: new Date(2020, 0, 23), y: 0},
    {x: new Date(2020, 0, 24), y: 0},
    {x: new Date(2020, 0, 25), y: 0},
    {x: new Date(2020, 0, 26), y: 0},
    {x: new Date(2020, 0, 27), y: 0},
    {x: new Date(2020, 0, 28), y: 0},
    {x: new Date(2020, 0, 29), y: 0},
    {x: new Date(2020, 0, 30), y: 0},
    {x: new Date(2020, 0, 31), y: 0}];
    
const precip = [
    {x: new Date(2020,0, 1), y: 1.13},
    {x: new Date(2020,0, 2), y: 0.51},
    {x: new Date(2020,0, 3), y: 0.69},
    {x: new Date(2020,0, 4), y: 0.81},
    {x: new Date(2020,0, 5), y: 0.32},
    {x: new Date(2020,0, 6), y: 0.96},
    {x: new Date(2020,0, 7), y: 2.42},
    {x: new Date(2020,0, 8), y: 0.62},
    {x: new Date(2020,0, 9), y: 0.43},
    {x: new Date(2020,0,10), y: 0.28},
    {x: new Date(2020,0,11), y: 2.83},
    {x: new Date(2020,0,12), y: 1.35},
    {x: new Date(2020,0,13), y: 0.17},
    {x: new Date(2020,0,14), y: 0.63},
    {x: new Date(2020,0,15), y: 0.2},
    {x: new Date(2020,0,16), y: 0.14},
    {x: new Date(2020,0,17), y: 0.29},
    {x: new Date(2020,0,18), y: 0.18},
    {x: new Date(2020,0,19), y: 0},
    {x: new Date(2020,0,20), y: 0},
    {x: new Date(2020,0,21), y: 0.2},
    {x: new Date(2020,0,22), y: 0.68},
    {x: new Date(2020,0,23), y: 1.91},
    {x: new Date(2020,0,24), y: 1.51},
    {x: new Date(2020,0,25), y: 1.28},
    {x: new Date(2020,0,26), y: 0.48},
    {x: new Date(2020,0,27), y: 0.51},
    {x: new Date(2020,0,28), y: 1.3},
    {x: new Date(2020,0,29), y: 0.26},
    {x: new Date(2020,0,30), y: 0.65},
    {x: new Date(2020,0,31), y: 1.42}];

    const maxTemp = [
        {x: new Date(2020,0, 1), y: 48},
        {x: new Date(2020,0, 2), y: 44},
        {x: new Date(2020,0, 3), y: 42},
        {x: new Date(2020,0, 4), y: 52},
        {x: new Date(2020,0, 5), y: 37},
        {x: new Date(2020,0, 6), y: 40},
        {x: new Date(2020,0, 7), y: 47},
        {x: new Date(2020,0, 8), y: 45},
        {x: new Date(2020,0, 9), y: 36},
        {x: new Date(2020,0,10), y: 31},
        {x: new Date(2020,0,11), y: 37},
        {x: new Date(2020,0,12), y: 36},
        {x: new Date(2020,0,13), y: 36},
        {x: new Date(2020,0,14), y: 31},
        {x: new Date(2020,0,15), y: 27},
        {x: new Date(2020,0,16), y: 31},
        {x: new Date(2020,0,17), y: 31},
        {x: new Date(2020,0,18), y: 33},
        {x: new Date(2020,0,19), y: 40},
        {x: new Date(2020,0,20), y: 45},
        {x: new Date(2020,0,21), y: 45},
        {x: new Date(2020,0,22), y: 39},
        {x: new Date(2020,0,23), y: 40},
        {x: new Date(2020,0,24), y: 43},
        {x: new Date(2020,0,25), y: 44},
        {x: new Date(2020,0,26), y: 45},
        {x: new Date(2020,0,27), y: 45},
        {x: new Date(2020,0,28), y: 40},
        {x: new Date(2020,0,29), y: 41},
        {x: new Date(2020,0,30), y: 43},
        {x: new Date(2020,0,31), y: 43}];
const minTemp = [
    {x: new Date(2020,0, 1), y: 38},
    {x: new Date(2020,0, 2), y: 32},
    {x: new Date(2020,0, 3), y: 33},
    {x: new Date(2020,0, 4), y: 35},
    {x: new Date(2020,0, 5), y: 32},
    {x: new Date(2020,0, 6), y: 31},
    {x: new Date(2020,0, 7), y: 39},
    {x: new Date(2020,0, 8), y: 34},
    {x: new Date(2020,0, 9), y: 25},
    {x: new Date(2020,0,10), y: 26},
    {x: new Date(2020,0,11), y: 30},
    {x: new Date(2020,0,12), y: 31},
    {x: new Date(2020,0,13), y: 27},
    {x: new Date(2020,0,14), y: 21},
    {x: new Date(2020,0,15), y: 21},
    {x: new Date(2020,0,16), y: 22},
    {x: new Date(2020,0,17), y: 26},
    {x: new Date(2020,0,18), y: 25},
    {x: new Date(2020,0,19), y: 32},
    {x: new Date(2020,0,20), y: 40},
    {x: new Date(2020,0,21), y: 36},
    {x: new Date(2020,0,22), y: 35},
    {x: new Date(2020,0,23), y: 35},
    {x: new Date(2020,0,24), y: 40},
    {x: new Date(2020,0,25), y: 38},
    {x: new Date(2020,0,26), y: 38},
    {x: new Date(2020,0,27), y: 35},
    {x: new Date(2020,0,28), y: 35},
    {x: new Date(2020,0,29), y: 37},
    {x: new Date(2020,0,30), y: 32},
    {x: new Date(2020,0,31), y: 32}];



class CedarFalls extends React.Component {
  render() {
    return (
      <div>
        <VictoryChart
            height={250} width={528}
            theme={VictoryTheme.material}
            scale={{x:"time"}}
            >        
            
                <VictoryLabel x={264} y={24} 
                    textAnchor="middle"                
                    text="Cedar Lake Snow and Precipitation for January 2020"
                    style={[
                        { fill: "black", fontSize: 17 },
                    ]}
                />
                <VictoryLine
                    // domain={{
                    //     x: [new Date(2020, 0, 1), new Date(2020, 0, 31)],
                    //     y: [0, 8]
                    // }}
                    style={{
                    data: { stroke: "#c43a31" },
                    parent: { border: "1px solid #ccc"}
                    }}
                    data= {snow}
                />
                <VictoryLine
                    style={{
                    data: { stroke: "#0000FF" },
                    parent: { border: "1px solid #ccc"}
                    }}
                    data= {precip}
                />
                <VictoryLegend x={350} y={50}
                    orientation="vertical"
                    gutter={20}
                    rowGutter={{ top: 0, bottom: 10 }}
                    data={[
                        { name: "Snowfall", symbol: { fill: "#c43a31" } }, { name: "Precipitation", symbol: { fill: "#0000FF" }}
                    ]}
                />
                <VictoryAxis dependentAxis
                    label={"Inches"}
                    style={{
                        axisLabel:{
                            padding: 35
                        }
                    }}
                />
                <VictoryAxis independentAxis
                    label={"Date"}
                    style={{
                        axisLabel:{
                            padding: 25
                        }
                    }}            
                />
            </VictoryChart>
            
            <VictoryChart
            height={250} width={528}
            theme={VictoryTheme.material}
            scale={{x:"time"}}
            >
                <VictoryLabel x={264} y={24} 
                    textAnchor="middle"                
                    text="Cedar Lake Min and Max Temp. for January 2020"
                    style={[ 
                        { fill: "black", fontSize: 17 },
                    ]}
                 
                />
                <VictoryLine
                    style={{
                    data: { stroke: "#c43a31" },
                    parent: { border: "1px solid #ccc"}
                    }}
                    data= {maxTemp}
                />
                <VictoryLine
                    style={{
                    data: { stroke: "#0000FF" },
                    parent: { border: "1px solid #ccc"}
                    }}
                    data= {minTemp}
                />
                <VictoryLegend x={350} y={135}
                    orientation="vertical"
                    gutter={20}
                    rowGutter={{ top: 0, bottom: 10 }}
                    data={[
                        { name: "Max Temp.", symbol: { fill: "#c43a31" } }, { name: "Min Temp.", symbol: { fill: "#0000FF" }}
                    ]}
                />
                <VictoryAxis dependentAxis
                    label={"Temperature (°F)"}
                    style={{
                        axisLabel:{
                            padding: 35
                        }
                    }}
                />
                <VictoryAxis independentAxis
                    label={"Date"}
                    style={{
                        axisLabel:{
                            padding: 25
                        }
                    }}            
                />
            </VictoryChart>
      </div>
    );
  }
}

export default CedarFalls;






