import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import CedarFalls from './CedarFalls';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'What We Offer',
    paragraph: 'Piece offers advanced real estate data-sets, right at your fingertips. Use them to find the perfect property in seconds.'
  };

  const comparisonHeader = {
    title: 'Compare With Competitors',
    paragraph: ['With all of these features, ', <i>Piece</i>, ' offers more information about listings than any of our competitors.']
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  -DATA INSIGHTS-
                  </div>
                <h3 className="mt-0 mb-12">
                Unique data sets all in one place.
                  </h3>
                <p className="m-0">
                Visualize all the data you could ever need, and more! Gain valuable in depth insights on individual properties from historical weather data to directions to the nearest grocery store. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/* <Image
                  src={require('./../../assets/images/cedar-graphs.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} /> */}
                <CedarFalls/>
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                -MAPPING-
                  </div>
                <h3 className="mt-0 mb-12">
                Get the lay of the land from above.
                  </h3>
                <p className="m-0">
                View 3D topographic maps to get the lay of the land. Even enable overlays for fire and flood risk so you know where the danger is. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  -INTUITIVE RATING-
                  </div>
                <h3 className="mt-0 mb-12">
                Analyze the property using our data driven rating system

                  </h3>
                <p className="m-0">
                Sometimes seeing the raw data is too dense to understand. Our data driven model rates properties on different scales, taking into account diverse datasets to help you take your property searching to the next level.  
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/analysis.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
          {/* <SectionHeader data={comparisonHeader} className="center-content section-inner" />
          <div className="center-content reveal-from-bottom illustration-element-01" data-reveal-value="20px">
              <Image
                className="has-shadow"
                src={require('./../../assets/images/comparison.jpeg')}
                alt="Hero"
                width={896}
                height={504} />
          </div> */}
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;