import React from 'react';
import AboutTeam from '../components/sections/AboutTeam';

const Team = () => {

  return (
    <>
      <AboutTeam invertMobile topDivider imageFill className="illustration-section-02"/>
    </>
  );
}

export default Team;

